import styled from "@emotion/styled"

export const H1 = styled.h1`
  font-size: ${({ theme }) => theme.typography.h1}px;
  line-height: 1.23;
  font-weight: 600;
  font-style: normal;
  letter-spacing: 0px;
  color: ${({ theme }) => theme.color.secondary};
  width: ${({ width }) => (width ? `${width}` : "auto")};
  text-align: ${({ align }) => align};
  margin: ${({ margin }) => margin || 0};
  &:focus {
    outline: none;
  }
  @media only screen and (max-width: 970px) {
    width: ${({ mobileWidth }) => (mobileWidth ? `${mobileWidth}` : "auto")};
    text-align: center;
    font-size: ${({ mobileFontSize }) => mobileFontSize};
    &:focus {
      outline: none;
    }
  }
`

export const H2 = styled.h2`
  font-size: ${({ theme }) => theme.typography.h2}px;
  font-weight: 600;
  color: ${({ theme }) => theme.color.secondary};
  width: ${({ width }) => (width ? `${width}` : "auto")};
  text-align: ${({ align }) => align};
  margin: ${({ margin }) => margin || 0};
  @media only screen and (max-width: 970px) {
    width: ${({ mobileWidth }) => (mobileWidth ? `${mobileWidth}` : "auto")};
    text-align: ${({ mobileAlign }) => (mobileAlign ? mobileAlign : "")};
  }
`

export const H3 = styled.h3`
  font-size: ${({ theme }) => theme.typography.h3}px;
  font-weight: 800;
  letter-spacing: -0.4px;
  color: ${({ theme }) => theme.color.secondary};
  width: ${({ width }) => (width ? `${width}` : "auto")};
  text-align: ${({ align }) => align};
  margin: ${({ margin }) => margin || 0};
  @media only screen and (max-width: 970px) {
    width: ${({ mobileWidth }) => (mobileWidth ? `${mobileWidth}` : "auto")};
    text-align: center;
  }
`

export const P = styled.p`
  font-size: ${({ theme }) => theme.typography.p}px;
  font-weight: normal;
  letter-spacing: -0.4px;
  color: ${({ theme }) => theme.color.darkBackgroundSecondary};
  width: ${({ width }) => (width ? `${width}` : "auto")};
  text-align: ${({ align }) => align};
  margin: ${({ margin }) => margin || 0};
  line-height: 46px;
  @media only screen and (max-width: 970px) {
    width: ${({ mobileWidth }) => (mobileWidth ? `${mobileWidth}` : "auto")};
    text-align: ${({ textAlign }) => textAlign || ""};
  }
`

export const Caption = styled(P)`
  font-size: ${({ theme }) => theme.typography.caption}px;
  color: ${({ theme }) => theme.color.captionColor};
  margin: ${({ margin }) => margin || 0};
  letter-spacing: -0.28px;
  line-height: 1.75em;
  width: ${({ width }) => width};
  @media only screen and (max-width: 970px) {
    width: ${({ mobileWidth }) => (mobileWidth ? mobileWidth : "initial")};
    text-align: ${({ textAlign }) => textAlign || "initial"};
  }
  @media only screen and (max-width: 450px) {
    width: ${({ minWidth }) => minWidth};
  }
`

export const Source = styled.a`
  font-size: ${({ theme }) => theme.typography.info}px;
  font-weight: bold;
  color: ${({ theme }) => theme.color.source};
  cursor: pointer;
  padding: ${({ padding }) => padding};
  text-decoration: none;
  opacity: ${({ opacity }) => (opacity ? opacity : "0.6")};
  transition: opacity 0.3s ease-in;
  &:visited,
  :active {
    color: ${({ theme }) => theme.color.source};
  }
  &:hover {
    opacity: 1;
  }
`

export const Span = styled.span`
  margin: ${({ margin }) => margin};
  font-size: ${({ theme }) => theme.typography.span}px;
  color: ${({ theme }) => theme.color.secondary};
`
