/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Header from "./header"
import { keyframes } from "@emotion/core"
import styled from "@emotion/styled"
import { FlexContainer } from "../styled/layout.styled"
import Footer from "./Footer"

const scrollPageAnimation = keyframes`
0%{opacity: 0;}
100%{opacity: 1;}
`

const StyledFlexContainer = styled(FlexContainer)`
  animation: ${scrollPageAnimation} 0.4s both;

  @media only screen and (max-width: 970px) {
    padding: 15px 0 140px 0;
  }
`

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} />
      <StyledFlexContainer flexDirection="column" padding="50px 0 50px 0">
        {children}
      </StyledFlexContainer>
      <Footer />
    </>
  )
}

export default Layout
