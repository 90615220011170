import React from "react"
import { FlexContainer, FlexItem } from "../styled/layout.styled"
import { Caption, Source } from "../styled/typography.styled"
import styled from "@emotion/styled"
import Pdf from "../assets/pdf/Sarkis_Kozanoghli_-_Javascript_Developer.pdf"

const StyledSource = styled(Source)`
  font-weight: normal;
  font-size: 14px;
  &:hover {
    color: #fff;
  }
`

const StyledFlexContainer = styled(FlexContainer)`
  border-top: 2px solid #333;
  position: absolute;
  background-color: #252525;
  border-radius: 0 0 40px 40px;
  bottom: 0;
  left: 0;
  @media only screen and (max-width: 970px) {
    border-radius: 0;
  }
`

const StyledCaption = styled(Caption)`
  color: #aaa;
`

const Footer = () => {
  return (
    <StyledFlexContainer
      mobileFlexDirection="column"
      flexWrap="wrap"
      width="100%"
      justifyContent="space-between"
      alignItems="center"
    >
      <FlexContainer
        mobilePadding="15px 0 15px 0px"
        padding="15px 0 15px 50px"
        flexWrap="wrap"
        width="50%"
        mobileWidth="100%"
        justifyContent="flex-start"
        mobileJustifyContent="center"
      >
        <StyledSource
          href="https://www.linkedin.com/in/sarkis-kozanoghli-a10869154/?challengeId=AQGoTsjGnwKANgAAAXMev_qFCAw5mqQJWOP4OGOZ_szMlR_weCzmk6gntHtw-GVXXhNsIHDqM6VSx0uZidkqxmiHv44aaeme5g&submissionId=afc0d6c7-f4d7-1e16-6bb0-fb8b33cd577a"
          padding="0 0 0 20px"
          target="_blank"
          rel="noreferrer noopener"
        >
          Linked-in
        </StyledSource>
        <StyledSource
          href="https://github.com/sarkis1231"
          padding="0 0 0 20px"
          target="_blank"
          rel="noreferrer noopener"
        >
          Git-hub
        </StyledSource>
        <StyledSource
          href={Pdf}
          padding="0 0 0 20px"
          target="_blank"
          rel="noreferrer noopener"
          download
        >
          Download CV
        </StyledSource>
      </FlexContainer>
      <FlexItem mobilePadding="0 0 15px 0" padding="15px 40px 15px 0">
        <StyledCaption>
          © {new Date().getFullYear()} All rights reserved, Sarkis Kozanoghli
        </StyledCaption>
      </FlexItem>
    </StyledFlexContainer>
  )
}

export default Footer
