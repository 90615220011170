import React from "react"
import styled from "@emotion/styled"

const StyledButton = styled.button`
  width: ${({ width }) => width};
  background-color: ${({ theme }) => theme.color.btnBgColor};
  box-shadow: 0 10px 10px -8px rgba(0, 0, 0, 0.78);
  color: ${({ theme }) => theme.color.secondary};
  padding: 10px;
  border-radius: ${({ radius }) => (radius ? radius : "25px")};
  border: ${({ borderColor, theme }) =>
    borderColor
      ? `2px solid ${theme.color.btnBorderDefault}`
      : `2px solid ${theme.color.btnBorderBlue}`};
  cursor: pointer;
  transition: all 0.3s ease-in;
  margin: ${({ margin }) => margin};
  white-space: nowrap;

  a {
    text-decoration: none;
    color: ${({ theme }) => theme.color.secondary};
  }

  &:hover:enabled {
    background-color: ${({ borderColor, theme }) =>
      borderColor ? theme.color.btnBorderDefault : theme.color.btnBorderBlue};
    color: ${({ theme, borderColor }) =>
      borderColor ? theme.color.btnBgColor : theme.color.btnBorderDefault};
    transform: translateY(-3px);
    a {
      color: ${({ theme, borderColor }) =>
        borderColor ? theme.color.btnBgColor : theme.color.btnBorderDefault};
    }
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    cursor: no-drop;
    opacity: 0.6;
    &:hover:enabled {
      background-color: ${({ theme }) => theme.color.btnBgColor};
    }
  }

  @media only screen and (max-width: 970px) {
    width: ${({ mobileWidth }) => mobileWidth};
  }
  @media only screen and (max-width: 450px) {
    width: ${({ minWidth }) => minWidth};
  }
`

const Button = ({
  children,
  width,
  borderColor,
  mobileWidth,
  minWidth,
  disabled,
  margin,
  type,
  onClick,
  radius,
}) => {
  return (
    <StyledButton
      width={width}
      borderColor={borderColor}
      mobileWidth={mobileWidth}
      minWidth={minWidth}
      disabled={disabled}
      type={type}
      margin={margin}
      onClick={onClick}
      radius={radius}
    >
      {children}
    </StyledButton>
  )
}

export default Button
