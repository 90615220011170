import { useEffect } from "react"

function useScrollingElement(trigger) {
  useEffect(() => {
    if (trigger) {
      document.body.style.overflow = "hidden"
      document.body.style.touchAction = "none"
    } else {
      document.body.style.overflow = "auto"
      document.body.style.touchAction = "auto"
    }
  }, [trigger])
}

export default useScrollingElement
