import pdf from "../assets/pdf/Sarkis_Kozanoghli_-_Javascript_Developer.pdf"

export const EXPERIENCE_EDUCATION_LIST = [
  {
    id: 0,
    topic: "Experience",
    list: [
      {
        id: 8,
        date: "May 2021 - current",
        placeName: "EPAM Systems",
        title: "Software Engineer",
        description: "Web Application Development",
        height: 0,
      },
      {
        id: 7,
        date: "Sep 2020 - May 2021",
        placeName: "Exio.tech",
        title: "JavaScript Developer",
        description: "Web Application Development",
        height: 0,
      },
      {
        id: 0,
        date: "Nov 2019 - Sep 2020",
        placeName: "Out source freelance",
        title: "JavaScript Developer",
        description: "A POS system for restaurants service",
        height: 0,
      },
      {
        id: 1,
        date: "Jun 2020 - Jul 2020",
        placeName: "With Minta",
        title: "JavaScript Developer",
        description: "A platform for video creation app related to shopify app",
        height: 0,
      },
      {
        id: 2,
        date: "Apr 2019 - Jan 2020",
        placeName: "Movehash, Yerevan",
        title: "JavaScript Developer",
        description: "Cryptocurrency exchanging and tracking rate platform.",
        height: 0,
      },
      {
        id: 3,
        date: "Oct 2019 - Jun 2020",
        placeName: "Out source freelance",
        title: "JavaScript Developer",
        description:
          "A service to create events and make planes and share it with friends",
        height: 0,
      },
      {
        id: 4,
        date: "Nov 2018 - Aug 2019",
        placeName: "Out source freelance",
        title: "JavaScript Developer",
        description: "Fashion eCommerce website.",
        height: 1,
      },
    ],
  },
  {
    id: 1,
    topic: "Education",
    list: [
      {
        id: 0,
        date: "2017 - 2020",
        placeName: "European University Of Armenia",
        title: "Bachelor's degree",
        description: "Information Technology and Applied Mathematics",
        height: 0,
      },
      {
        id: 1,
        date: "May 2017 — Jul 2017",
        placeName: "Microsoft.I.C Armenia",
        title: "UI/UX Certificate",
        description: "UI/Ux Designer",
        height: 1,
      },
    ],
  },
]

export const SKILLS_LANGUAGES_LIST = [
  {
    id: 0,
    topic: "Skills",
    skillsList: [
      {
        id: 0,
        title: "JavaScript",
        skillsPercent: "80%",
      },
      {
        id: 1,
        title: "ReactJs",
        skillsPercent: "80%",
      },
      {
        id: 4,
        title: "HTML5",
        skillsPercent: "80%",
      },
      {
        id: 2,
        title: "CSS",
        skillsPercent: "80%",
      },
      {
        id: 3,
        title: "NodeJs",
        skillsPercent: "50%",
      },
    ],
  },

  {
    id: 1,
    topic: "Languages",
    skillsList: [
      {
        id: 0,
        title: "Arabic",
        skillsPercent: "100%",
      },
      {
        id: 1,
        title: "Armenian",
        skillsPercent: "100%",
      },
      {
        id: 2,
        title: "English",
        skillsPercent: "80%",
      },
    ],
  },
]

export const HEADER_LIST = [
  {
    id: 0,
    name: "Home",
    location: "/",
  },
  {
    id: 1,
    name: "Resume",
    location: "/resume",
  },
  {
    id: 2,
    name: "Contact",
    location: "/contact",
  },
  {
    id: 3,
    name: "Download CV",
    location: pdf,
    anchor: true,
  },
]

export const RESUME_DETAILS = [
  {
    id: 0,
    topic: "Software Skills",
    names: ["PhotoShop", "Illustrator", "Zeplin", "Web Storm"],
  },
  {
    id: 1,
    topic: "Technologies",
    names: ["Gatsby-Js", "Styled component", "Material-UI", "Redux"],
  },
  {
    id: 2,
    topic: "Links",
    names: [
      {
        name: "LinkedIn",
        link:
          "https://www.linkedin.com/in/sarkis-kozanoghli-a10869154/?challengeId=AQGoTsjGnwKANgAAAXMev_qFCAw5mqQJWOP4OGOZ_szMlR_weCzmk6gntHtw-GVXXhNsIHDqM6VSx0uZidkqxmiHv44aaeme5g&submissionId=afc0d6c7-f4d7-1e16-6bb0-fb8b33cd577a",
      },
      {
        name: "Github",
        link: "https://github.com/sarkis1231",
      },
      {
        name: "npm-profile",
        link: "https://www.npmjs.com/~sarkis_kozanoghli",
      },
    ],
    link: true,
  },
  {
    id: 3,
    topic: "Details",
    names: [
      {
        name: "sakooghly@gmail.com",
        link: "mailto:sakooghly@gmail.com",
      },
      {
        name: "+ 374 41 121 085",
        link: "tel:+374 41 121 085",
      },
    ],
    link: true,
  },
]
