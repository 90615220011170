import React, { useCallback, useEffect } from "react"
import { Link, navigate } from "gatsby"
import { FlexContainer, FlexItem } from "../styled/layout.styled"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import { Span } from "../styled/typography.styled"
import { HEADER_LIST } from "../constants/data.constants"
import logo from "../assets/images/sako.png"
import Button from "./reuseAble/Button"
import useScrollingElement from "../hooks/useScrollingElement"

const StyledFlexContainer = styled(FlexContainer)`
  @media only screen and (max-width: 970px) {
    position: fixed;
    width: 100%;
    background-color: #222;
    top: 0;
    left: 0;
    right: 0;
    padding: 10px 25px;
    z-index: 3;
    box-sizing: border-box;
    transition: all 0.3s ease-in-out;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.08);
  }
`

const StyledFlexItem = styled(FlexItem)`
  a {
    color: #aaa;
    text-decoration: none;
    font-size: 14px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 0;
    transition: all 0.3s ease-in;
    &:hover {
      color: white;
      text-decoration: none;
    }
  }
  @media only screen and (max-width: 970px) {
    margin: 0 0 40px 0;
    a {
      font-size: 16px;
    }
  }
`

const StyledMenuItemWrapper = styled(FlexContainer)`
  @media only screen and (max-width: 970px) {
    display: none;
  }
`

const StyledSpan = styled(Span)`
  cursor: pointer;
  @media only screen and (max-width: 970px) {
    font-size: 18px;
    transition: all 0.3s ease-in-out;
  }
`

const activeStyle = {
  color: "white",
  textDecoration: "none",
}

const IconWrapper = styled(FlexContainer)`
  display: none;
  flex-direction: column;
  height: 21px;
  justify-content: space-evenly;
  cursor: pointer;
  @media only screen and (max-width: 970px) {
    display: flex;
    flex-direction: column;
    height: 15px;
    justify-content: space-evenly;
    padding: ${({ padding }) => padding};
  }
`

const StyledImage = styled.img`
  width: 44px;
  @media only screen and (max-width: 970px) {
    width: 34px;
    transition: all 0.3s ease-in-out;
  }
`

const StyledBurgerMenuWrapper = styled.div`
  width: 20px;
  height: 20px;
  display: block;
  position: relative;
  float: right;
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
  cursor: pointer;
  span {
    display: block;
    position: absolute;
    height: 2px;
    width: 20px;
    background: white;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: 0.25s ease-in-out;
  }
  ${({ trigger }) =>
    trigger
      ? css`
          span:nth-of-type(1) {
            top: 7px;
            transform: rotate(135deg);
          }
          span:nth-of-type(2) {
            opacity: 0;
            left: -200px;
            top: 7px;
          }
          span:nth-of-type(3) {
            top: 7px;
            transform: rotate(-135deg);
          }
        `
      : css`
          span:nth-of-type(1) {
            top: 0;
          }
          span:nth-of-type(2) {
            top: 6px;
          }
          span:nth-of-type(3) {
            top: 12px;
          }
        `};
`

const StyledSideNavMenu = styled(FlexContainer)`
  display: none;
  @media only screen and (max-width: 970px) {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background: #222;
    border: none;
    width: 200px;
    height: 100vh;
    overflow: hidden;
    ${({ trigger }) =>
      trigger
        ? css`
            transform: translateX(0);
          `
        : css`
            transform: translateX(300px);
          `};
    transition: transform 200ms ease-in-out;
  }
`

const StyledOverlay = styled.div`
  display: none;
  @media only screen and (max-width: 970px) {
    position: fixed;
    top: 0;
    display: inline-block;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    z-index: 1;
    overflow: hidden;
    background: rgba(0, 0, 0, 0.8);
    ${({ trigger }) =>
      trigger
        ? css`
            visibility: visible;
          `
        : css`
            visibility: hidden;
          `};
    ${({ trigger }) =>
      trigger
        ? css`
            opacity: 1;
          `
        : css`
            opacity: 0;
          `};
    transition: opacity 200ms ease-in-out;
  }
`

const Header = () => {
  const [modalIsOpen, setIsOpen] = React.useState(false)
  const [isHeaderReversed, setIsHeaderReversed] = React.useState(false)
  useScrollingElement(modalIsOpen)

  function openModal() {
    setIsOpen(prevState => !prevState)
  }

  const handleScroll = useCallback(function handleScroll(e) {
    e.preventDefault()
    if (window.scrollY > 50 && window.innerWidth <= 970) {
      setIsHeaderReversed(true)
    } else if (window.scrollY < 50) {
      setIsHeaderReversed(false)
    }
  }, [])

  useEffect(() => {
    window.addEventListener("scroll", handleScroll)
    return () => window.removeEventListener("scroll", handleScroll)
  }, [handleScroll])

  return (
    <>
      <StyledFlexContainer
        alignItems="center"
        justifyContent="space-between"
        flexWrap="wrap"
        className={isHeaderReversed ? "nav-shadow" : ""}
        style={
          isHeaderReversed
            ? { boxShadow: "0px 0px 10px 0px rgba(0,124,237,.88)" }
            : null
        }
      >
        <FlexContainer
          cursor="pointer"
          mobileAlignItems="center"
          alignItems="center"
          onClick={() => navigate("/")}
        >
          <StyledImage src={logo} alt="" />
          <FlexItem padding="0 0 0 10px">
            <StyledSpan
              style={
                isHeaderReversed
                  ? { textShadow: "rgb(0, 124, 237,0.95) 0px 1px 5px" }
                  : null
              }
            >
              <b>Sarkis</b> Kozanoghli
            </StyledSpan>
          </FlexItem>
        </FlexContainer>
        <StyledMenuItemWrapper
          width="35%"
          justifyContent="flex-end"
          alignItems="center"
        >
          {HEADER_LIST.map(({ id, name, location, anchor }) => (
            <StyledFlexItem margin="0 0 0 30px" key={id}>
              {anchor ? (
                <a
                  href={location}
                  target="_blank"
                  rel="noreferrer noopener"
                  download
                >
                  <Button radius="5px">{name} </Button>
                </a>
              ) : (
                <Link activeStyle={activeStyle} to={`${location}`}>
                  {name}
                </Link>
              )}
            </StyledFlexItem>
          ))}
        </StyledMenuItemWrapper>
        <IconWrapper onClick={() => openModal()}>
          <StyledBurgerMenuWrapper trigger={modalIsOpen}>
            <span />
            <span />
            <span />
          </StyledBurgerMenuWrapper>
        </IconWrapper>
      </StyledFlexContainer>
      <StyledSideNavMenu trigger={modalIsOpen}>
        {HEADER_LIST.map(({ id, name, location, anchor }) => (
          <StyledFlexItem margin="0 0 0 30px" key={id}>
            {anchor ? (
              <a
                href={location}
                target="_blank"
                rel="noreferrer noopener"
                download
              >
                <Button radius="5px">{name} </Button>
              </a>
            ) : (
              <Link activeStyle={activeStyle} to={`${location}`}>
                {name}
              </Link>
            )}
          </StyledFlexItem>
        ))}
      </StyledSideNavMenu>
      <StyledOverlay
        trigger={modalIsOpen}
        onClick={() => setIsOpen(() => false)}
      />
    </>
  )
}

export default Header
